<div id="wrapper">
	<app-top-bar></app-top-bar>
	<app-left-bar></app-left-bar>
	<div class="content-page">
		<div class="content">
			<div class="container-fluid">
				<router-outlet></router-outlet>
			</div>
		</div>
		<!-- <footer class="footer">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-6">
						2015 -
						<script>document.write(new Date().getFullYear())</script> &copy; UBold theme by <a href="">Coderthemes</a>
					</div>
					<div class="col-md-6">
						<div class="text-md-right footer-links d-none d-sm-block">
							<a href="javascript:void(0);">About Us</a>
							<a href="javascript:void(0);">Help</a>
							<a href="javascript:void(0);">Contact Us</a>
						</div>
					</div>
				</div>
			</div>
		</footer> -->
	</div>
</div>