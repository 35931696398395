<div class="navbar-custom">
	<div class="container-fluid">
		<!-- LOGO -->
		<div class="logo-box">
			<a href="index.html" class="logo logo-dark text-center">
				<span class="logo-sm">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
				</span>
				<span class="logo-lg">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
				</span>
			</a>

			<a href="index.html" class="logo logo-light text-center">
				<span class="logo-sm">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
				</span>
				<span class="logo-lg">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
				</span>
			</a>
		</div>
		<ul class="list-unstyled topnav-menu topnav-menu-left m-0">
			<li class="d-lg-none">
				<button class="button-menu-mobile" (click)="toggleNavbar()">
					<i [ngClass]="navbarMenu ? 'fe-x' : 'fe-menu'"></i>
				</button>
			</li>
			<li class="drop down d-lg-inline-block topbar-dropdown float-right" style="position: relative;">
				<a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" (click)="showLanguage = !showLanguage">
					{{'narrow-window.language' | translate}}
				</a>
				<div class="dropdown-menu dropdown-menu-right" [class.show]="showLanguage" style="position: absolute; transform: translate3d(-106px, 70px, 0px); top: 0px; left: 0px; will-change: transform;" x-placement="bottom-end">
			
					<!-- item-->
					<a *ngFor="let language of languages" (click)="changeLanguage(language.code)" class="dropdown-item">
						<span class="mr-2" [innerHTML]="language.flag"></span> {{language.name}}
					</a>
				</div>
			</li>
		</ul>
		<div class="clearfix"></div>
	</div>
</div>