import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../common/auth.service';
import { TokenService } from '../common/token.service';
import { LoadingService } from '../common/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthService, private toastr: ToastrService, private tokenService: TokenService, private router: Router, private loading: LoadingService, private translate: TranslateService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((evt: any) => {
        if (evt.body) {
          this.loading.end();
          if (evt.body.token) {
            this.tokenService.updateToken(evt.body.token);
            this._auth.updateUser();
          }
          let lang = this.translate.currentLang;
          let message = evt.body.message;
          if (lang == 'ko') {
            message = evt.body.message_ko;
          }
          if (evt.body.success && message) {
            let head = 'Success';
            if(lang == 'ko') {
              head = '성공';
            }
            this.toastr.success(message, head);
          }
          if (evt.body.error && message) {
            let head = 'Error';
            if (lang == 'ko') {
              head = '오류';
            }
            this.toastr.error(message, head);
          }
        }
      }),
      catchError(res => {
        this.loading.end();
        if (res.status === 401) {
          // auto logout if 401 response returned from api
          this._auth.logout();
          // this.router.navigateByUrl('/dashboard');
        }
        let lang = this.translate.currentLang;
        let error = res.error.message || res.statusText;
        if(lang == 'ko') {
          error = res.error.message_ko || res.statusText;
        }
        let r = '';
        // console.log(error);
        if (typeof error === 'object') {
          Object.keys(error).forEach(field => {
            const e = error[field];
            r += '<p>' + field.substr(0, 1).toUpperCase() + field.substr(1, 100) + ': ' + e[0] + '</p>';
          });
        }
        if (r) {
          this.toastr.error(r, '', {
            timeOut: 6000,
            enableHtml: true
          });
        } else {
          let head = 'Error';
          if (lang == 'ko') {
            head = '오류';
          }
          this.toastr.error(error, head);
        }
        return throwError(res);
      }));
  }
}
