import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';


@Injectable({
	providedIn: 'root'
})
export class PermissionService {
	constructor(private authService: AuthService) {
	}
	public havePerm(role: string[]): boolean {
		if (this.authService.userDetails && role.indexOf(this.authService.userDetails.role) > -1) {
			return true;
		}
		return false;
	}
	public check(permission): boolean {
		if(permission) {
			const p = permission.split(',');
			return this.havePerm(p);
		}
		return true
	}
}
