import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import { VMessageComponent } from './v-message/v-message.component';
import { LbAutoCompleteComponent } from './lb-auto-complete/lb-auto-complete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PermissionsDirective } from './permissions.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePcikerComponent } from './date-picker/date-pciker.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PromptComponent } from './prompt/prompt.component';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorI18n } from './PaginatorI18n';
import { LanguageModule } from '../language/language.module';
import { PaginationPageDirective } from './pagination-page.directive';

@NgModule({
	declarations: [
		VMessageComponent,
		PermissionsDirective,
		PaginationPageDirective,
		LbAutoCompleteComponent,
		DatePcikerComponent,
		PromptComponent
	],
	entryComponents: [DatePcikerComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatRippleModule,
		MatCheckboxModule,
		MatDialogModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatChipsModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		LanguageModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		VMessageComponent,
		MatDialogModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatButtonModule,
		LbAutoCompleteComponent,
		MatTabsModule,
		MatRippleModule,
		MatChipsModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		PermissionsDirective,
		PaginationPageDirective,
		DatePcikerComponent,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule
	],
	providers: [
		{
			provide: MatPaginatorIntl, deps: [TranslateService],
			useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
		}
	]
})
export class FormControllerModule { }
