<i class="mdi mdi-close clearFilter" *ngIf="(fControl.value != '' || fControl.value.length != 0) && clear"
	(click)="fControl.setValue(''); onSelectionChange('')"></i>
<mat-form-field appearance="{{ appearance }}">
	<mat-label>{{label}}</mat-label>
	<mat-select #selectElem (openedChange)="searchInput.focus(); searchInput.value=''; searchFunction('')" [placeholder]="placeholder"
		[formControl]="fControl" [multiple]="multiple" [(ngModel)]="selectedValue"
		(selectionChange)="onSelectionChange($event.value)">
		<mat-form-field appearance="{{'legacy'}}">
			<input autocomplete="off" style="padding-left: 16px; padding-right: 16px; box-sizing: border-box;" (keydown)="$event.stopPropagation()" #searchInput matInput type="text"
				(input)="searchFunction(searchInput.value)" />
		</mat-form-field>
		<mat-select-trigger>
			{{ onDisplayString() }}
		</mat-select-trigger>
		<mat-option *ngFor="let option of options; trackBy: trackByFn" [disabled]="option.disabled" [class.px-1]="option.disabled" [value]="option[value]"
			[style.display]="hideOption(option) ? 'none' : 'flex'">{{ (option[display]) | translate}}
		</mat-option>
	</mat-select>
	<mat-error>
		<vmessage [form]="null" [control]="fControl"></vmessage>
	</mat-error>
</mat-form-field>