import { Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LeftmenuService {
	private _status = new BehaviorSubject<Boolean>(null);
	status = this._status.asObservable();
	constructor(
	) {
		this._status.next(false);
	}
	public toggle() {
		this._status.next(!this._status.value);
	}
}
