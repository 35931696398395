import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/_services/rest/user.service';
import { MustMatch } from 'src/_services/validators/must-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    this.form = this.formBuilder.group({
      old_password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24), Validators.pattern(/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s)/)]],
      new_password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24), Validators.pattern(/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s)/)]],
      confirm_password: ['', [Validators.required]]
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.form.valid) {
      const sub = this.userService.changePass(this.form.value).subscribe(res => {
        sub.unsubscribe();
      }, res =>{
        sub.unsubscribe();
      });
    }

  }
}
