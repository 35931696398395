import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { CookieService } from 'ngx-cookie-service';
import { navigationItem, User } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private checkLogin = new BehaviorSubject<boolean>(false);
  loginOB = this.checkLogin.asObservable();
  private user = new BehaviorSubject<User>(null);
  userInfo = this.user.asObservable();
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router, private tokenService: TokenService, private cookieService: CookieService) {
  }
  public login(email: string, password: string) {
    return this.http.post<{success: boolean, token?: string, message:string}>(environment.api + 'auth/', { email, password })
      .pipe(map(res => {
        if (res && res.token) {
          this.tokenService.updateToken(res.token);
          this.updateUser();
        }
        return res;
      }));
  }

  public get isLoggedIn(): boolean {
    let loggedin = !this.jwtHelper.isTokenExpired(this.tokenService.token);
    // if (loggedin) {
    // 	loggedin = Boolean(this.cookieService.get('sessionCookieId'));
    // }
    if (loggedin === false) {
      this.logout();
    }
    this.checkLogin.next(loggedin);
    return loggedin;
  }

  public get loggedIn(): boolean {
    return this.checkLogin.value;
  }

  public updateUser(): void {
    this.user.next(this.jwtHelper.decodeToken(this.tokenService.token));
  }
  public get userDetails(): User {
    if (this.checkLogin.value === true) {
      if (this.user.value === null) {
        this.updateUser();
      }
      return this.user.value;
    } else {
      return null;
    }
  }

  logout() {
    // remove user from local storage to log user out
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.delete('sessionCookieId');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessTokenMaster');
      localStorage.removeItem('menu');
    }
    this.checkLogin.next(false);
    this.user.next(null);
    this.router.navigate(['/auth/login']);
  }

  public forgetPassword(data: Object) {
    return this.http.post(environment.api + 'auth/forgetPassword', data);
  }
  public chnagePassword(data: Object) {
    return this.http.post(environment.api + 'auth/updatePassword', data);
  }
}
