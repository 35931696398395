import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/_services/common/auth.service';
import { LeftmenuService } from 'src/_services/common/leftmenu.service';
import { navigationItem } from 'src/_services/models';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
	naviIteam: navigationItem[];
	navbarMenu = false;
	showLanguage = false;
	childMenu = [];
	languages = [
		{
			flag: '&#127482;&#127480;',
			name: "English",
			code: "en"
		},
		{
			flag: '&#127472;&#127479;',
			name: "Korean",
			code: "ko"
		}
	]

	constructor(private translate: TranslateService, public _leftMenu: LeftmenuService, private _auth: AuthService, private router: Router) {
	}
	changeLanguage(code) {
		localStorage.setItem('a-m.com-language', code);
		this.translate.use(code);
		this.showLanguage = false;
	}
  ngOnInit() {
  }

	navigate(url: string, event) {
		event.preventDefault();
		event.stopPropagation();
		this.router.navigateByUrl(url);
		this.navbarMenu = false;
	}

	logout() {
		this._auth.logout();
	}

  toggleNavbar() {
		this._leftMenu.toggle();
    // this.navbarMenu = !this.navbarMenu;
  }

	openSubMenu(label, event){
		event.preventDefault();
		event.stopPropagation();

		this.childMenu[label] = !this.childMenu[label]
	}
}
