import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../models';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = environment.api + 'user/';
  constructor(private http: HttpClient) {
  }
  public signup(FormData: Object): Observable<string> {
    return this.http.post<any>(environment.api + 'auth/signup', FormData)
      .pipe(map(res => {
        return res.message;
      }));
  }
  public verification(FormData: Object): Observable<string> {
    return this.http.post<any>(environment.api + 'auth/emailverify', FormData)
      .pipe(map(res => {
        return res.message;
      }));
  }
  public list(params: Params = null) {
    return this.http.get<{ count: number; data: User[] }>(this.url, { params: params });
  }

  public get(id: number) {
    return this.http.get<User>(this.url + id);
  }

  public checkEmail(email: string) {
    return this.http.get<any>(this.url + 'checkEmail/' + email);
  }
  public update(id: number, data: Object) {
    return this.http.put(this.url + id, data);
  }
  public create(data: Object) {
    return this.http.post(this.url, data);
  }
  public delete(id: number) {
    return this.http.delete(this.url + id);
  }

  public changePass(data: Object) {
    return this.http.post(this.url + "/changePassword", data);
  }

}