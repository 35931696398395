<div class="row">
    <div class="col-12">
        <div class="page-title-box">
            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item mt-1"><a href="javascript: void(0);">{{'change-password.home' | translate
                            }}</a></li>
                    <li class="breadcrumb-item mt-1"><a href="javascript: void(0);">{{'change-password.change_password'
                            | translate }}</a></li>
                </ol>
            </div>
            <h4 class="page-title">{{'change-password.change_password' | translate }}</h4>
        </div>
    </div>
</div>
<div class="row justify-content-center mt-5">
    <div class="col-md-8 col-lg-6 col-xl-4">
        <div class="card bg-pattern">
            <div class="card-body p-4">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group mb-0">
                        <mat-form-field>
                            <mat-label>{{'labels.current_password' | translate}}</mat-label>
                            <input matInput formControlName="old_password" type="password" data-cy="old_password"
                                placeholder="Enter your password">
                            <mat-error>
                                <vmessage field="password" [control]="form.controls.old_password"></vmessage>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group mb-0">
                        <mat-form-field>
                            <mat-label>{{'labels.new_password' | translate}}</mat-label>
                            <input matInput formControlName="new_password" type="password" data-cy="new_password"
                                placeholder="Enter your New Password">
                            <mat-error>
                                <vmessage field="password" [control]="form.controls.new_password"></vmessage>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group mb-0">
                        <mat-form-field>
                            <mat-label>{{'labels.confirm_password' | translate}}</mat-label>
                            <input matInput formControlName="confirm_password" type="password"
                                data-cy="confirm_password" placeholder="Enter your Confirm Password">
                            <mat-error>
                                <vmessage [form]="form" control="confirm_password"></vmessage>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <p>{{ 'labels.change_password_text' | translate}}</p>
                    <div class="text-center d-grid">
                        <button class="btn btn-primary" type="submit"> {{'labels.save' | translate}} </button>
                    </div>
                </form>
            </div> <!-- end card-body -->
        </div>
        <!-- end card -->
    </div> <!-- end col -->
</div>