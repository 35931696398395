import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/_services/common/auth.service';
import { TokenService } from 'src/_services/common/token.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sportsbase';
  ready = false;
  constructor(private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: any, private _auth: AuthService, private tokenService: TokenService, private cookieService: CookieService) {
    if (isPlatformBrowser(this.platformId)) {
      this.ready = true;
      this._auth.userDetails
    }
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      let language = localStorage.getItem('a-m.com-language');
      if(language) {
        this.translate.use(language);
      } else {
        language = this.translate.getBrowserLang();
        if (language) {
          this.translate.use(language.match(/en|ko/) ? language : 'en');
        }
      }
    }
  }
  // if (isPlatformBrowser(this.platformId)) {
  //   if (!this.cookieService.get('sessionCookieId')) {
  //     this.cookieService.set('sessionCookieId', "Test");
  //     this.tokenService.updateToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJzaGFua2xxQGdtYWlsLmNvbSIsImltYWdlIjoiaHR0cHM6Ly9pbWFnZXMudW5zcGxhc2guY29tL3Bob3RvLTE1MzU3MTM4NzUwMDItZDFkMGNmMzc3ZmRlP2l4aWQ9TW53eE1qQTNmREI4TUh4elpXRnlZMmg4TVh4OGRYTmxjbnhsYm53d2ZId3dmSHclM0QmaXhsaWI9cmItMS4yLjEmdz0xNTAmcT04MCIsImFjY2VzcyI6eyJkYXNoYm9hcmQiOnsidmlldyI6dHJ1ZX19LCJpYXQiOjE2MjM2MjUwMTcsImV4cCI6MTYzMzYyNTAxNn0.1AowoffPuoLcdB_WE560Rkz78TzyBD7EQgIkihVjajE");
  //   }
  //   this._auth.updateUser();
  //   this._auth.updateMenu([{ label: "Dashboard", icon: "mdi mdi-view-dashboard-outline", url: "/dasbhoard", open: false, childs: [] }, { label: "Players", icon: "mdi mdi-account-multiple-outline", url: "/players", open: false, childs: [{ label: "Users", url: "/users", icon: "mdi mdi-account-outline" }, { label: "Users 2", url: "/users", icon: "mdi mdi-account-outline" }] }, { label: "Test Menu", icon: "mdi mdi-account-multiple-outline", url: "/test", open: false, childs: [{ label: "Users", url: "/users", icon: "mdi mdi-account-outline" }, { label: "Users 2", url: "/users", icon: "mdi mdi-account-outline" }] }]);
  //   this.ready = true;
  // }

}
