<!-- ========== Left Sidebar Start ========== -->
<div class="left-side-menu">
	
	<div class="h-100">

		<!-- User box -->
		<div class="user-box text-center" *ngIf="userInfo | async as userData">
			<img src="{{userData.profile? userData.profile.image : '/assets/images/user.png'}}"
				onerror="this.src='/assets/images/user.png'" alt="user-img" title="Click to edit profile"
				class="rounded-circle avatar-md mb-2" />
				<div *ngIf="drpClass" class="back-drop" (click)="drpClass = !drpClass"></div>
				<div class="dropdown">
					<a href="javascript: void(0);" class="text-dark dropdown-toggle h5 mt-2 mb-1 d-block" (click)="drpClass = !drpClass">{{userData.name}}</a>
					<div class="dropdown-menu user-pro-dropdown {{(drpClass? 'show': '')}}">
						<!-- item-->
						<!-- <a href="javascript:void(0);" routerLink="" class="dropdown-item notify-item">
							<i class="fe-user mr-1"></i>
							<span>{{'narrow-window.my_account' | translate}}</span>
						</a> -->
						<!-- item-->
						<a (click)="drpClass = !drpClass" href="javascript:void(0);" routerLink="change-password" class="dropdown-item notify-item">
							<i class="fe-user mr-1"></i>
							<span>{{'narrow-window.change_password' | translate}}</span>
						</a>
						<!-- item-->
						<!-- <a href="javascript:void(0);" class="dropdown-item notify-item">
							<i class="fe-settings mr-1"></i>
							<span>{{'narrow-window.settings' | translate}}</span>
						</a> -->
						<!-- item-->
						<!-- <a href="javascript:void(0);" class="dropdown-item notify-item">
							<i class="fe-lock mr-1"></i>
							<span>{{'narrow-window.lock_screen' | translate}}</span>
						</a> -->
						<!-- item-->
						<button (click)="logout(); drpClass = !drpClass;" class="dropdown-item notify-item">
							<i class="fe-log-out mr-1"></i>
							<span>{{'narrow-window.logout' | translate}}</span>
						</button>
					</div>
				</div>
				<p class="text-muted">{{userData.position}}</p>
		</div>
		<!--- Sidemenu -->
		<div id="sidebar-menu">

			<ul id="side-menu">
				<ng-container *ngIf="naviIteam">
				<ng-container *ngFor="let item of naviIteam">
					<li routerLinkActive="menuitem-active" *ngIf="Permission(item.role)">
						<a routerLink="{{item.url}}" *ngIf="!item.childs">
							<i *ngIf="item.icon" [ngClass]="item.icon"></i>
							<span>{{'navigation.'+item.label | translate}}</span>
						</a>

						<a (click)="item.open = !item.open;" [ngClass]="{ 'oppened': item.open }" *ngIf="item.childs && item.childs.length > 0">
							<i *ngIf="item.icon" [ngClass]="item.icon"></i>
							<span>{{'navigation.'+item.label | translate}}</span>
							<span class="menu-arrow"></span>
						</a>
						<div class="collapse nav-second-level-hover" [ngClass]="{ 'show': item.open }" *ngIf="item.childs && item.childs.length">
							<ul class="nav-second-level">
							<ng-container *ngFor="let subitem of item.childs">
								<li routerLinkActive="menuitem-active"
									*ngIf="Permission(subitem.role)">
									<a routerLink="{{item.url}}/{{subitem.url}}" *ngIf="!subitem.childs">
										<i *ngIf="subitem.icon" [ngClass]="subitem.icon"></i>
										<span>{{'navigation.'+subitem.label | translate}}</span>
									</a>
									<a routerLink="{{item.url}}/{{subitem.url}}" [ngClass]="{ 'oppened': subitem.open }" *ngIf="subitem.childs && subitem.childs.length > 0">
										<i *ngIf="subitem.icon" [ngClass]="subitem.icon"></i>
										<span>{{'navigation.'+subitem.label | translate}}</span>
										<span class="menu-arrow"></span>
									</a>
									<div class="collapse nav-second-level-hover" [ngClass]="{ 'show': item.open }" *ngIf="subitem.childs && subitem.childs.length">
										<ul class="nav-second-level">
											<ng-container *ngFor="let sitem of subitem.childs">
												<li routerLinkActive="menuitem-active" *ngIf="Permission(sitem.role)">
													<a routerLink="{{item.url}}/{{subitem.url}}/{{sitem.url}}">
														<i *ngIf="sitem.icon" [ngClass]="sitem.icon"></i>
														<span>{{'navigation.'+sitem.label | translate}}</span>
													</a>
												</li>
											</ng-container>
										</ul>
									</div>
								</li>
								</ng-container>
							</ul>
						</div>
					</li>
				</ng-container>
				</ng-container>
			</ul>

		</div>
		<!-- End Sidebar -->

		<div class="clearfix"></div>

	</div>
	<!-- Sidebar -left -->
	<!-- <span class="powered">Powered by <a href="https://www.loopbrackets.com" target="_blank">LoopBrackets</a></span> -->
</div>