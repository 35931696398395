import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { navigationItem, User } from 'src/_services/models';
import { LeftmenuService } from 'src/_services/common/leftmenu.service';
import { AuthService } from 'src/_services/common/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { PermissionService } from 'src/_services/common/permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss']
})
export class LeftBarComponent implements OnInit, OnDestroy {

  userInfo: Observable<User>;
  menuSub: Subscription;
  drpClass = false;
  childMenu = [];
  naviIteam: navigationItem[] = [
    // {
    //   label: 'dashbaord',
    //   role: 'admin,sadmin',
    //   url: '/dashboard'
    // },
    {
      label: 'user_management',
      role: 'admin,sadmin',
      url: 'usermanagement',
      childs: [
        {
          label: 'user_list',
          role: 'admin,sadmin',
          url: '/users'
        },
        {
          label: 'company_list',
          role: 'sadmin',
          url: '/company'
        },
        {
          label: 'company_info',
          role: 'admin',
          url: '/company-info'
        }
      ]
    },
    {
      label: 'content_management',
      role: 'sadmin',
      url: 'contentmanagement',
      childs: [
        {
          label: 'content_list',
          role: 'sadmin',
          url: '/content'
        },
        {
          label: 'category_management',
          role: 'sadmin',
          url: 'categorymanagement',
          childs: [
            {
              label: 'main_category',
              url: 'category',
              role: 'sadmin'
            },
            {
              label: 'sub_category',
              url: 'subcategory',
              role: 'sadmin'
            }
          ]
        },
        {
          label: 'recomended_content_management',
          role: 'sadmin',
          url: 'recomendedcontent',
          childs: [
            {
              label: 'main_recommended_content',
              url: 'mainrecomended',
              role: 'sadmin'
            },
            {
              label: 'category_recommended_content',
              url: 'categoryrecomended',
              role: 'sadmin'
            }
          ]
        },
        {
          label: 'award_management',
          role: 'sadmin',
          url: '/award'
        }
      ]
    },
    {
      label: 'statics',
      role: 'admin,sadmin',
      url: 'stats',
      childs: [
        {
          label: 'app_access',
          role: 'admin,sadmin',
          url: 'appaccess'
        },
        {
          label: 'content',
          role: 'admin,sadmin',
          url: 'content'
        }
      ]
    },
    {
      label: 'etc',
      role: 'sadmin',
      childs: [
        {
          label: 'change_terms',
          role: 'sadmin',
          url: '/terms'
        },
        {
          label: 'event_list',
          role: 'sadmin',
          url: '/events'
        },
        {
          label: 'option_management',
          role: 'sadmin',
          url: '/menu-option'
        }
      ]
    }
  ];
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    public _leftMenu: LeftmenuService,
    private _auth: AuthService,
    private router: Router,
    private authService: AuthService,
    private _p: PermissionService,
    public translate: TranslateService
  ) {
    this.userInfo = this.authService.userInfo;
  }
  Permission(role: string) {
    if (role) {
      const p = role.split(',');
      return this._p.havePerm(p);
    }
    return false;
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.menuSub = this._leftMenu.status.subscribe(status => {
        if (status) {
          this.renderer.addClass(document.body, 'sidebar-enable');
        } else {
          this.renderer.removeClass(document.body, 'sidebar-enable');
        }
      })
    }
  }
  logout() {
    this._auth.logout();
  }
  navigateChild(url: string, event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigateByUrl(url);
  }
  openSubMenu(label, event) {
    event.preventDefault();
    event.stopPropagation();

    this.childMenu[label] = !this.childMenu[label]
  }
  ngOnDestroy() {
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
  }


}