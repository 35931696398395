import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullWindowComponent } from './full-window/full-window.component';
import { NarrowWindowComponent } from './narrow-window/narrow-window.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { TokenService } from 'src/_services/common/token.service';
import { LoaderInterceptor } from 'src/_services/interceptors/loader.interceptor';
import { ErrorInterceptor } from 'src/_services/interceptors/error.interceptor';
import { LoadingModule } from './shared/modules/loading/loading.module';
import { ToastrModule } from 'ngx-toastr';
import { FullLoaderComponent } from './full-loader/full-loader.component';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TopBarComponent } from './narrow-window/top-bar/top-bar.component';
import { LeftBarComponent } from './narrow-window/left-bar/left-bar.component';
import { CookieService } from 'ngx-cookie-service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormControllerModule } from './shared/modules/form-controller/form-controller.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};
export function jwtOptionsFactory(tokenService: TokenService) {
  return {
    tokenGetter: () => {
      return tokenService.token;
    },
    whitelistedDomains: environment.whitelistedDomains,
  }
}
@NgModule({
  declarations: [
    AppComponent,
    FullWindowComponent,
    NarrowWindowComponent,
    FullLoaderComponent,
    TopBarComponent,
    LeftBarComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingModule,
    FormControllerModule,
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService],
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    })
  ],
  providers: [
    CookieService,
    TokenService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
