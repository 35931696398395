import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/_services/common/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FullWindowComponent } from './full-window/full-window.component';
import { NarrowWindowComponent } from './narrow-window/narrow-window.component';

const routes: Routes = [
  {
    path: 'auth',
    component: FullWindowComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      }
    ]
  },
  {
    path: '',
    component: NarrowWindowComponent,
    children: [
      {
        path: '',
        redirectTo: '/usermanagement/users',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'usermanagement/company',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'usermanagement/company-info',
        loadChildren: () => import('./company-info/company-info.module').then(m => m.CompanyInfoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'usermanagement/users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contentmanagement/content',
        loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
        canActivate: [AuthGuard]
      },      
      {
        path: 'contentmanagement/categorymanagement/category',
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contentmanagement/categorymanagement/subcategory',
        loadChildren: () => import('./sub-category/sub-category.module').then(m => m.SubCategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contentmanagement/recomendedcontent/mainrecomended',
        loadChildren: () => import('./recomended/recomended.module').then(m => m.RecomendedModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contentmanagement/recomendedcontent/categoryrecomended',
        loadChildren: () => import('./recommended-category/recommended-category.module').then(m =>m.RecommendedCategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contentmanagement/award',
        loadChildren: () => import('./award/award.module').then(m => m.AwardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'terms',
        loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu-option',
        loadChildren: () => import('./menu-option/menu-option.module').then(m => m.MenuOptionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'stats',
        loadChildren: () => import('./statics/statics.module').then(m => m.StaticsModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
